import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/home/runner/work/prof/prof/deps/docs/src/components/AutoDocLayout.jsx";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h2>{`Name`}</h2>
    <p><strong parentName="p">{`step crypto nacl box seal`}</strong>{` -- produce an authenticated and encrypted ciphertext`}</p>
    <h2>{`Usage`}</h2>
    <pre><code parentName="pre" {...{
        "className": "language-raw"
      }}>{`step crypto nacl box seal <nonce> <recipient-pub-key> <priv-key>
[--raw]
`}</code></pre>
    <h2>{`Description`}</h2>
    <p>{`Reads plaintext from STDIN and writes an encrypted and authenticated
ciphertext to STDOUT. The "box" can be open by the a recipient who has access
to the private key corresponding to `}<inlineCode parentName="p">{`recipient-pub-key`}</inlineCode>{`.`}</p>
    <p>{`This command uses an implementation of NaCl's crypto_box function.`}</p>
    <p>{`For examples, see `}<strong parentName="p">{`step help crypto nacl box`}</strong>{`.`}</p>
    <h2>{`Positional arguments`}</h2>
    <p><inlineCode parentName="p">{`nonce`}</inlineCode>{`
Must be unique for each distinct message for a given pair of keys.`}</p>
    <p>{`To use a binary nonce use the prefix 'base64:' and the standard base64
encoding. e.g. base64:081D3pFPBkwx1bURR9HQjiYbAUxigo0Z`}</p>
    <p><inlineCode parentName="p">{`recipient-pub-key`}</inlineCode>{`
The path to the public key of the intended recipient of the sealed box.`}</p>
    <p><inlineCode parentName="p">{`priv-key`}</inlineCode>{`
The path to the private key used for authentication.`}</p>
    <h2>{`Options`}</h2>
    <p><strong parentName="p">{`--raw`}</strong>{`
Do not base64 encode output`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      